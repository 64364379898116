import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Head from '../components/head';
import Layout from '../components/layout';
// import CommentForm from '../components/commentForm';
// import Comments from '../components/comments';


//* Query variables accessed from context object
//* defined in createPages function (gatsby-node.js).
export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
    allCommentsYaml(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          name
          email
          comment
          date
        }
      }
    }
  }
`

const Blog = (props) => {
  //* Options for documentToReactComponents function.
  //* This info exists on the body/json graphql query for posts.
  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const nodeRoot = node.data.target.fields;
        const alt = nodeRoot.title['en-US'];
        const url = nodeRoot.file['en-US'].url;
        return <img src={url} alt={alt} />
      }
    }
  }

  // console.log('blog data:', props);
  const context = props.pageContext;
  const blogData = props.data.contentfulBlogPost;
  const baseSiteUrl = 'https://authorseanallen.com';
  const postSlug = context.slug;

  // const comments = props.data.allCommentsYaml;


  return (
    <Layout>
      <Head title={blogData.title} />
      <Helmet>
        <meta name="description" content="" />
        <link rel="canonical" href={`${baseSiteUrl}/${postSlug}`} />
      </Helmet>
      <section className="hero dark">
        <div className="container blog">
          <h4>{blogData.title}</h4>
          <p className="date-stamp">{blogData.publishedDate}</p>
          {documentToReactComponents(blogData.body.json, options)}
          <hr />
          {/* <Comments comments={comments} /> */}
          {/* <CommentForm slug={context.slug} /> */}
          <div className="prev-next-links">
            {
              context.previous &&
              <div className="previous-link">
                <Link to={context.previous}>&laquo; Previous post</Link>
              </div>
            }
            {
              context.next &&
              <div className="next-link">
                <Link to={context.next}>Next post &raquo;</Link>
              </div>
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Blog;